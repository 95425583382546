import React from "react"

const IconSearch = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      className={props.className}
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9,15.1l-3-3c1.7-2.1,1.4-5.3-0.7-7s-5.3-1.4-7,0.7s-1.4,5.3,0.7,7c1.8,1.5,4.5,1.5,6.3,0l3,3c0.2,0.2,0.5,0.2,0.7,0
	c0,0,0,0,0,0C16,15.7,16.1,15.4,15.9,15.1C15.9,15.2,15.9,15.2,15.9,15.1z M9,13c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S11.2,13,9,13
	z"
      />
    </svg>
  )
}

export default IconSearch
