import React from "react"

const IconMenu = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      className={props.className}
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
    >
      <path d="M4.5,5.5h11C15.8,5.5,16,5.7,16,6l0,0c0,0.3-0.2,0.5-0.5,0.5h-11C4.2,6.5,4,6.3,4,6l0,0C4,5.7,4.2,5.5,4.5,5.5z" />
      <path d="M4.5,9.5h11c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5h-11C4.2,10.5,4,10.3,4,10l0,0C4,9.7,4.2,9.5,4.5,9.5z" />
      <path d="M4.5,13.5h11c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5h-11C4.2,14.5,4,14.3,4,14l0,0C4,13.7,4.2,13.5,4.5,13.5z" />
    </svg>
  )
}

export default IconMenu
