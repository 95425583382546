import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Button, Box, Flex } from "@chakra-ui/react"
import { getURL, setURL, startsWith } from "../../tools/helper"

const LexikonNavigation = props => {
  const n = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]

  let nEnabled = props.letters

  return n.map((letter, i) => (
    <Box key={i}>
      <Button
        // title={letter}
        tabIndex={0}
        onClick={
          letter === props.currentLetter
            ? () => {
                setURL([
                  ["menu", getURL("menu")],
                  ["vokabular", ""],
                ])
                props.setCurrentLetter("")
              }
            : () => {
                setURL([
                  ["menu", getURL("menu")],
                  ["vokabular", letter],
                ])
                props.setCurrentLetter(letter)
              }
        }
        variant="ghost"
        borderRadius="90px"
        p="0px"
        disabled={!nEnabled.includes(letter)}
        isDisabled={!nEnabled.includes(letter)}
        _disabled={{
          background: "transparent",
          fontWeight: "normal",
          opacity: "0.62",
          pointerEvents: "none",
        }}
        _hover={{ background: "buttonTertiary.500" }}
        _active={{ background: "buttonTertiary.500" }}
        isActive={letter === props.currentLetter}
      >
        {letter}
      </Button>
    </Box>
  ))
}

const Lexikon = props => {
  const data = useStaticQuery(graphql`
    query LexikonQuery {
      allWpLexicons {
        nodes {
          title
          id
          content
        }
      }
    }
  `)

  const [currentLetter, setCurrentLetter] = useState()

  const lexikon = data.allWpLexicons.nodes

  const unsorted = []
  const letters = [""]

  for (let i = 0; i < lexikon.length; i++) {
    unsorted.push([lexikon[i].title, lexikon[i]])
  }

  let sorted = unsorted.sort()

  for (let i = 0; i < sorted.length; i++) {
    if (letters.includes(sorted[i][0][0]) !== true) {
      letters.push(sorted[i][0][0])
      sorted[i][1].firstOfLetter = true
    } else {
      sorted[i][1].firstOfLetter = false
    }
  }

  // Set Current Letter from URL

  let url = getURL("vokabular")

  // Filter
  useEffect(() => {
    if (url) {
      setCurrentLetter(getURL("vokabular"))
    }
  }, [currentLetter, url])

  if (currentLetter !== undefined) {
    sorted = sorted.filter(word => {
      // return word[0][0] === currentLetter
      return startsWith(word[0], currentLetter)
    })
  }

  return (
    <nav>
      <Flex direction="row" flexWrap="wrap" mt="24px">
        <LexikonNavigation
          letters={letters}
          currentLetter={currentLetter}
          setCurrentLetter={setCurrentLetter}
        />
      </Flex>
      <Box ml="2">
        {sorted.length > 0 &&
          sorted.map(i => (
            <div
              className="mt-4"
              key={i[1].id}
              id={i[1].firstOfLetter ? "glossary_" + i[1].title[0] : ""}
            >
              <h1 id={i[1].title} className="text-s3 mb-1 font-semibold">
                {i[1].title}
              </h1>
              <div dangerouslySetInnerHTML={{ __html: i[1].content }} />
            </div>
          ))}
      </Box>
    </nav>
  )
}

export default Lexikon
