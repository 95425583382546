import PropTypes from "prop-types"
import React from "react"
import { Box } from "@chakra-ui/react"

const checkLayerZero = (currentPath, link, lang, homeSlug, currentIndex) => {
  if (link === null) return
  const URLparts = currentPath.split("/")
  const LINKparts = link.split("/")
  if (lang === "de_DE") {
    return (
      URLparts.length === 3 &&
      LINKparts.length === 2 &&
      URLparts[1] === homeSlug &&
      currentIndex === 0
    )
  } else {
    return (
      URLparts.length === 4 &&
      LINKparts.length === 2 &&
      URLparts[2] === homeSlug &&
      currentIndex === 0
    )
  }
}

const checkLayerOne = (currentPath, link, lang, homeSlug, currentIndex) => {
  if (link === null) return
  const URLparts = currentPath.split("/")
  const LINKparts = link.split("/")
  if (lang === "de_DE") {
    return (
      URLparts.length === 3 &&
      LINKparts.length === 2 &&
      URLparts[1] === LINKparts[1] &&
      currentIndex === 1
    )
  } else {
    return (
      URLparts.length === 4 &&
      LINKparts.length === 2 &&
      URLparts[2] === LINKparts[1] &&
      currentIndex === 1
    )
  }
}

const checkLayerTwo = (currentPath, link, lang, homeSlug, currentIndex) => {
  if (link === null) return
  const URLparts = currentPath.split("/")
  const LINKparts = link.split("/")

  if (lang === "de_DE") {
    return (
      (URLparts.length === 4 || URLparts.length === 5) &&
      URLparts[3] === LINKparts[3] &&
      currentIndex === 2
    )
  } else {
    return (
      (URLparts.length === 5 || URLparts.length === 6) &&
      URLparts[4] === LINKparts[4] &&
      currentIndex === 2
    )
  }
}

const checkIfDotIsActive = (link, lang, homeSlug, currentIndex) => {
  if (link === null) return
  if (typeof window !== `undefined`) {
    const currentPath = window.location.pathname
    if (currentPath === link || currentPath.slice(0, -1) === link) {
      return true
    } else {
      // Complex
      // IS LAYER 0 DE || EN
      if (checkLayerZero(currentPath, link, lang, homeSlug, currentIndex)) {
        return true
      }
      // IS LAYER 1 DE || EN
      if (checkLayerOne(currentPath, link, lang, homeSlug, currentIndex)) {
        return true
      }
      // IS LAYER 2 DE || EN
      if (checkLayerTwo(currentPath, link, lang, homeSlug, currentIndex)) {
        return true
      }
    }
    return
  }
}

const Dot = props => {
  if (checkIfDotIsActive(props.link, props.lang, props.homeSlug, props.index)) {
    return (
      <>
        <Box
          d="inline-block"
          mt="8px"
          w="10px"
          h="10px"
          rounded="full"
          borderWidth="3px"
          borderColor="black"
          background="black"
        />
      </>
    )
  } else {
    return (
      <>
        <Box
          d="inline-block"
          mt="8px"
          w="10px"
          h="10px"
          rounded="full"
          borderWidth="3px"
          borderColor="black"
          background="transition"
          transition="all"
          transitionDuration="300ms"
        />
      </>
    )
  }
}

Dot.propTypes = {
  active: PropTypes.bool,
}

Dot.defaultProps = {
  active: false,
}

export default Dot
