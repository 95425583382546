import PropTypes from "prop-types"
import React from "react"
import IconMenu from "../Icons/IconMenu"

const Hamburger = props => {
  return (
    <>
      <div aria-label={props.ariaLabel}>
        <IconMenu className="w-5" />
      </div>
    </>
  )
}

Hamburger.propTypes = {
  active: PropTypes.bool,
}

Hamburger.defaultProps = {
  active: false,
}

export default Hamburger
