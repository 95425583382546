import { graphql, useStaticQuery } from "gatsby"

const useGenUrl = (slug = null, postType, locale, schwerpunkt = null) => {
  /**
   *  URL Struktur
   *  0 -> Empty
   *  1 -> forschung, forschungsschwerpunkt, mobilitaetsschwerpunkt, zukunftsbild
   *  2 -> Slug
   *  3 -> projektziele oder Slug
   * */
  const strings = useStaticQuery(graphql`
    query SlugQuery {
      de_DE: wpModeSettings(
        slug: { eq: "global" }
        locale: { id: { eq: "de_DE" } }
      ) {
        acf_global_settings {
          mobilitaetsschwerpunktSlug
          forschungSlug
          forschungsschwerpunktSlug
          projektzieleSlug
          zukunftsbildSlug
        }
      }
      en_US: wpModeSettings(
        slug: { eq: "global" }
        locale: { id: { eq: "en_US" } }
      ) {
        acf_global_settings {
          mobilitaetsschwerpunktSlug
          forschungSlug
          forschungsschwerpunktSlug
          projektzieleSlug
          zukunftsbildSlug
        }
      }
    }
  `)

  let langPrefix = locale === "en_US" ? "/en" : ""
  switch (postType) {
    case "wissenschaftsschwerpunkt":
      return slug
        ? `${langPrefix}/${strings[locale].acf_global_settings.forschungsschwerpunktSlug}/${slug}`
        : `${langPrefix}/${strings[locale].acf_global_settings.forschungsschwerpunktSlug}`
    case "wissenschaft":
      let out = slug
        ? `${langPrefix}/${strings[locale].acf_global_settings.forschungSlug}/${slug}`
        : `${langPrefix}/${strings[locale].acf_global_settings.forschungSlug}`
      return out
    case "zukunftsbild":
      return slug
        ? `${langPrefix}/${strings[locale].acf_global_settings.zukunftsbildSlug}/${slug}`
        : `${langPrefix}/${strings[locale].acf_global_settings.zukunftsbildSlug}`

    case "mobilitaetsschwerpunkt":
      return slug
        ? `${langPrefix}/${strings[locale].acf_global_settings.mobilitaetsschwerpunktSlug}/${slug}`
        : `${langPrefix}/${strings[locale].acf_global_settings.mobilitaetsschwerpunktSlug}`
    case "projektziel":
      return slug
        ? `${langPrefix}/${strings[locale].acf_global_settings.mobilitaetsschwerpunktSlug}/${schwerpunkt}/${strings[locale].acf_global_settings.projektzieleSlug}/${slug}`
        : `${langPrefix}/${strings[locale].acf_global_settings.mobilitaetsschwerpunktSlug}/${schwerpunkt}/${strings[locale].acf_global_settings.projektzieleSlug}`

    default:
      break
  }
}

export default useGenUrl
