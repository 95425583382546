import sanitizeHtml from "sanitize-html"

// ⛑ Helper to cut the connection of the state to Redux-Store
const makeImmutableCopy = selector => {
  return JSON.parse(JSON.stringify(selector))
}

// ⛑ Helper to Insert RAW HTML
const insertStyledHTMLContent = (contentString, options = {}) => {
  let sanitizedHtml = sanitizeHtml(contentString, options)
  // console.log("SANITIZE", sanitizedHtml)

  if (sanitizedHtml === "null") {
    sanitizedHtml = ""
  }

  const returnObject = {
    __html: sanitizedHtml,
  }
  return returnObject
}

// ⛑ Helper to clean String for Anchor
const cleanStringForAnchor = string => {
  return string?.replace(/[\s.&!?/%$&€äüö0-9]/g, "")
}

// ⛑ Helper to getURLParams
const getURL = paramName => {
  if (typeof window !== `undefined`) {
    const URLparams = new URLSearchParams(window.location.search)
    return URLparams.get(paramName)
  }
}

// ⛑ Helper to setURLParams
/**
 *
 * @param {[string, *]} params [[name, value],...]
 * @param {*} reset
 */
const setURL = (params, reset = false) => {
  if (typeof window !== `undefined`) {
    if (window.history.pushState) {
      let newurl = null
      if (reset) {
        newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      } else {
        newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?`
        params.forEach((param, i) => {
          if (i === 0) {
            newurl = newurl + `${param[0]}=${param[1]}`
          } else {
            newurl = newurl + `&${param[0]}=${param[1]}`
          }
        })
      }
      window.history.pushState({ path: newurl }, "", newurl)
    }
  }
}

// Does a string start with
/**
 * Does a string start with
 * @param {*} word
 * @param {*} search
 * @param {*} rawPos start position
 * @returns
 */
const startsWith = function (word, search, rawPos) {
  var pos = rawPos > 0 ? rawPos | 0 : 0
  return word.substring(pos, pos + search.length) === search
}

// Prevent Scrolling

const disableScroll = () => {
  if (typeof window !== `undefined`) {
    // Get the current page scroll position
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

    // console.log("DISABLE SCROLL")
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop)
    }
  }
}

const enableScroll = () => {
  if (typeof window !== `undefined`) {
    // console.log("ENABLE SCROLL")
    window.onscroll = function () {}
  }
}

export {
  makeImmutableCopy,
  insertStyledHTMLContent,
  cleanStringForAnchor,
  getURL,
  setURL,
  disableScroll,
  enableScroll,
  startsWith,
}
