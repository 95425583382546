// * Farbwerte für Icons
// Die im CMS fest gesetzte Palette von Mode (Stand 18.11.2021) wird hier mit den Abstufungen für die Icons der Karte erweitert. Wenn die Werte im CMS geändert werden, muss hier auch der jeweilige Wert angepasst werden - als Fallback werden Annäherungen mit HSV generiert.
// "name" ist der Farbname zur Orientierung. "value" ist der im CMS eingetragene Wert (Kartenhintergrund). "variation" ist die Farbe der Icons, der Outline des aktuellen Ortes auf der Übersicht, und der Hintergrund der Headerbar (90%).

const colors = [
  {
    name: "gray",
    value: "#EDF2F7",
    variation: "#ffffff",
  },
  {
    name: "green",
    value: "#C6F6D5",
    variation: "#38A169",
  },
  {
    name: "blue",
    value: "#BEE3F8",
    variation: "#3182CE",
  },
  {
    name: "purple",
    value: "#E9D8FD",
    variation: "#805AD5",
  },
  {
    name: "modeSunYellow",
    value: "#FFF7D1",
    variation: "#FFEC99",
  },
  {
    name: "modeLeafGreen",
    value: "#ECF8D8",
    variation: "#D3EFA4",
  },
  {
    name: "modeEarthBrown",
    value: "#ede2d9",
    variation: "#AF7C5C",
  },
  {
    name: "modePlume",
    value: "#F1DCF4",
    variation: "#DDADE6",
  },
  {
    name: "modeDeepBlue",
    value: "#d2daf4",
    variation: "#AAB7E9",
  },
  {
    name: "modeWaterBlue",
    value: "#D1E8FF",
    variation: "#0076EF",
  },
  {
    name: "modePaleBlue",
    value: "#E3F7FD",
    variation: "#54CDF2",
  },
]

export function hexToHSL(H) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0
  if (H.length === 4) {
    r = "0x" + H[1] + H[1]
    g = "0x" + H[2] + H[2]
    b = "0x" + H[3] + H[3]
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2]
    g = "0x" + H[3] + H[4]
    b = "0x" + H[5] + H[6]
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0

  if (delta === 0) h = 0
  else if (cmax === r) h = ((g - b) / delta) % 6
  else if (cmax === g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return [h, s, l]
}

function colorFallback(groundColor) {
  let iconColor = hexToHSL(groundColor)
  return `hsl(${iconColor[0] - 4}, ${Math.floor(iconColor[1])}%, ${
    iconColor[2] - 40
  }%)`
}

export function getColorVariation(groundColor) {
  const res = colors.find(
    element => element.value.toLowerCase() === groundColor.toLowerCase()
  )
  if (res === undefined || res === null) {
    return colorFallback(groundColor)
  }
  const variation = colors.find(
    element => element.value.toLowerCase() === groundColor.toLowerCase()
  ).variation
  return variation
}
