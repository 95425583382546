import React from "react"

const defaultState = {
  portal: null,
  setPortal: () => {},
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
  state = {
    portal: null,
  }

  setPortal = portal => {
    this.setState({ portal })
  }

  render() {
    const { children } = this.props
    const { portal } = this.state
    return (
      <ThemeContext.Provider
        value={{
          portal,
          setPortal: this.setPortal,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }
