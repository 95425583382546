import React from "react"
import PropTypes from "prop-types"
import { Button, Center } from "@chakra-ui/react"

const IconClose = props => {
  // responsive ist der responsive Close bei Languages

  let display = {}
  if (props.responsive) {
    display = {
      base: props.responsive ? "inherit" : "none",
      md: props.responsive ? "inherit" : "none",
    }
  } else {
    if (props.isLanguage) {
      display = {
        base: props.isLanguage ? "none" : "inherit",
        md: props.isLanguage ? "inherit" : "inherit",
      }
    }
  }

  return (
    <Button
      variant="unstyled"
      background="transparent"
      rounded="full"
      _hover={{ background: "buttonTertiary.500" }}
      aria-label="Suchen"
      onClick={props.onClick}
      w="24px"
      display={display}
    >
      <Center>
        <svg
          className={props.className}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </Center>
    </Button>
  )
}

IconClose.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

IconClose.defaultProps = {
  className: "",
  onClick: () => {},
}

export default IconClose
