/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import { Box } from "@chakra-ui/react"
// 🗺 Map
import Map from "./Map/Map"
import { batchActions } from "redux-batched-actions"

// ⚙️ GUI
import SidebarIndicator from "./SidebarIndicator/SidebarIndicator"
import { useDispatch } from "react-redux"
import isMobile from "ismobilejs"
import { navigate } from "gatsby"

const Layout = ({ children, location, menuActive }) => {
  const sendActionToStore = useDispatch()

  useEffect(() => {
    if (location) {
      let code = location.pathname.split("/")[1]
      if (code !== "en") {
        sendActionToStore(
          batchActions(
            [
              {
                type: "change/lang",
                payload: "",
              },
              {
                type: "change/localeId",
                payload: "de_DE",
              },
            ],
            "LOCALE_BATCH"
          )
        )
        // sendActionToStore({
        //   type: "change/localeId",
        //   payload: "de_DE",
        // })
      } else {
        sendActionToStore(
          batchActions(
            [
              {
                type: "change/lang",
                payload: code,
              },
              {
                type: "change/localeId",
                payload: "en_US",
              },
            ],
            "LOCALE_BATCH"
          )
        )
        // sendActionToStore({
        //   type: "change/lang",
        //   payload: code,
        // })
        // sendActionToStore({
        //   type: "change/localeId",
        //   payload: "en_US",
        // })
      }
      let path = location.pathname.split("/")
      path.splice(path.length - 1, 1)
      let previousPath = path.join("/")
      sendActionToStore(
        batchActions(
          [
            {
              type: "change/previousPath",
              payload: previousPath,
            },
            {
              type: "change/path",
              payload: location.pathname,
            },
            {
              type: "change/translatedUrl",
              payload: location.pathname,
            },
          ],
          "PATH_BATCH"
        )
      )

      // push previous URL
      // sendActionToStore({
      //   type: "change/previousPath",
      //   payload: previousPath,
      // })

      // push new URL
      // let newPath = path.join("/")
      // sendActionToStore({
      //   type: "change/path",
      //   payload: location.pathname,
      // })

      // set new translated Url
      // sendActionToStore({
      //   type: "change/translatedUrl",
      //   payload: location.pathname,
      // })
    }
  }, [location, sendActionToStore])
  const isSSR = typeof window === "undefined"

  useEffect(() => {
    if (
      location &&
      !isSSR &&
      isMobile(window.navigator).phone &&
      location.pathname !== "/phone"
    ) {
      navigate("/phone")
    }
  }, [isSSR, location])

  // ⬜️ Blank Pages
  const blankPages = [
    "/projectsceneDesigner/",
    "projectsceneDesigner",
    "/projectsceneDesigner",
    "/auth",
    "/karte",
    "karte",
    "/karte/",
  ]

  function checkBlankPage(path) {
    if (blankPages.includes(path)) {
      return true
    } else {
      return false
    }
  }

  const isBlankPage = checkBlankPage(location?.pathname.toString())

  // 🚫🗺 Mapless Pages
  const pagesWithMaps = [
    "/zukunftsbild",
    "/zukunftsbild/",
    "/en/vision",
    "/en/vision/",
    "/mobilitaetsschwerpunkte",
    "/mobilitaetsschwerpunkte/",
    "/en/mobility-focus",
    "/en/mobility-focus/",
    "/uebersicht",
    "/uebersicht/",
    "/en/overview",
    "/en/overview/",
    "/en",
    "/en/",
    "/phone",
    "/phone/",
    "/en/phone",
    "/en/phone/",
    "/projektziele",
    "/projektziele/",
    "/projectgoals",
    "/projectgoals/",
  ]

  function checkMaplessPage(path) {
    let isMaplessPage = true

    for (let URLofPageWithMap of pagesWithMaps) {
      if (
        path &&
        (path === "/" || path === "/en" || path.includes(URLofPageWithMap))
      ) {
        isMaplessPage = false
        break
      }
    }

    return isMaplessPage
  }

  let pagesWithoutMenu = ["/phone", "/phone/", "/en/phone", "/en/phone/"]

  function checkMenulessPage(path) {
    let isMenulessPage = false

    for (let URLofPageWithoutMenu of pagesWithoutMenu) {
      if (path && path.includes(URLofPageWithoutMenu)) {
        isMenulessPage = true
        break
      }
    }

    return isMenulessPage
  }

  let pagesWithoutSidebarIndicator = [
    "/uebersicht/",
    "/uebersicht",
    "/en/overview",
    "/en/overview/",
  ]

  function checkIndicatorlessPage(path) {
    let isSidebarIndicatorlessPage = false
    for (let URLofPageWithoutSidebar of pagesWithoutSidebarIndicator) {
      if (path && path.includes(URLofPageWithoutSidebar)) {
        isSidebarIndicatorlessPage = true
        break
      }
    }
    return isSidebarIndicatorlessPage
  }

  const isMaplessPage = checkMaplessPage(location?.pathname.toString())
  const isMenulessPage = checkMenulessPage(location?.pathname.toString())
  const isSidebarIndicatorlessPage = checkIndicatorlessPage(
    location?.pathname.toString()
  )

  return (
    <>
      {!isBlankPage ? (
        <>
          <div id={`mode ${isMaplessPage ? "mapless" : ""}`}>
            {/* ⚙️ GUI*/}
            {/* <GUI /> */}

            <div>
              <div
                id="MarkerContainer"
                className="fixed overflow-hidden inset-0"
              ></div>
              {/* 🖼 UI*/}
              <div
                id="ui"
                className={`flex h-full w-screen max-w-full font-sans`}
              >
                {/* <WYSIWYGImageOverlay /> */}
                <Header
                  singlePage={isMaplessPage}
                  className="z-10"
                  menuActive={!isMenulessPage}
                />
                <Box d={["none", "none", "block"]}>
                  {!isMaplessPage && !isSidebarIndicatorlessPage ? (
                    <SidebarIndicator className="z-10 portrait:invisible Phone:invisible" />
                  ) : (
                    ""
                  )}
                </Box>
                <main className="z-0  h-full w-full overflow-auto">
                  {children}
                </main>
              </div>
              {!isSSR && !isMaplessPage ? <Map id="Map" /> : ""}
            </div>
          </div>
        </>
      ) : (
        <div id="blank">{children}</div>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
