import React from "react"
import { Suspense } from "react"
import { Canvas } from "@react-three/fiber"
import { Loader, Environment } from "@react-three/drei"
import Camera from "./Camera"
import Lights from "./Lights"
import LocationMarkers from "./LocationMarkers"
import Markers from "./Markers"
import { useStore, Provider, useSelector } from "react-redux"
import "../../styles/map.css"
import { graphql, useStaticQuery } from "gatsby"
import Model from "./Scene"
import ThemeContext from "./ThemeContext"

const Map = props => {
  // 🗄 Get Store to create new Provider
  const store = useStore()

  const MAP_VALUES = useSelector(state => state.map)

  // get marker from Store
  const MAP_MARKERS = useSelector(state => state.mapMarkers)
  const PREVIEW_MARKERS = useSelector(state => state.previewMarker)
  const LOCATIONS_ACTIVE = useSelector(state => state.locationsActive)
  // const PROJECT_SCENE = useSelector(state => state.projectScene)
  // const MARKER_PORTAL = useSelector(state => state.markerContainerPortal)
  // const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)

  const markerTypes = useStaticQuery(graphql`
    query MarkerQuery {
      allWpMapIcon {
        nodes {
          acf_map_icon {
            imageIcon {
              localFile {
                publicURL
              }
            }
          }
          slug
        }
      }
      allWpProjektziel {
        nodes {
          acf_scene {
            location {
              id
              name
            }
          }
          title
          slug
          acf_projektziel_content {
            groupLeft {
              textProjektname
              relationMobilitaetsschwerpunkt {
                ... on WpSchwerpunkt {
                  slug
                }
              }
            }
          }
          locale {
            locale
          }
        }
      }
      allWpLocation {
        nodes {
          name
          id
          acf_location {
            location {
              x
              y
            }
          }
          tax_translation {
            enTranslation
          }
        }
      }
    }
  `)

  // const markerProjects = markerTypes[`project_${CURRENT_LOCALE}`]
  // const markerLocations = markerTypes[`location_${CURRENT_LOCALE}`]

  const loaderStyle = {
    background: MAP_VALUES.groundColor,
  }

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div role="presentation" id={props.id}>
          <Canvas
            mode="concurrent"
            id="c"
            orthographic
            shadows
            // onCreated={state => state.gl.setClearColor("red")}
            gl={{ antialias: false, powerPreference: "low-power" }}
            // gl={{ antialias: false }}
          >
            <Provider store={store}>
              {MAP_MARKERS && theme.portal !== null && (
                <Markers
                  psd={props.psd}
                  portal={theme.portal}
                  data={MAP_MARKERS}
                  types={markerTypes}
                />
              )}
              {PREVIEW_MARKERS && theme.portal !== null && (
                <Markers
                  psd={props.psd}
                  portal={theme.portal}
                  data={PREVIEW_MARKERS}
                  isPreview
                  types={markerTypes}
                />
              )}
              {LOCATIONS_ACTIVE && (
                <LocationMarkers
                  projects={markerTypes.allWpProjektziel}
                  locations={markerTypes.allWpLocation}
                  portal={theme.portal}
                  locationsActive={LOCATIONS_ACTIVE}
                />
              )}
              <Camera psd={props.psd} />
              <Lights />
              {/* <fogExp2 attach="fog" args={[MAP_VALUES.groundColor, 0.015]} /> */}
              <Suspense fallback={null}>
                <Environment
                  files={"/assets/venice_sunset_1k.hdr"}
                  background={true}
                />
                {/* <MainScene groundColor={MAP_VALUES.groundColor} /> */}
                <Model groundColor={MAP_VALUES.groundColor} />
              </Suspense>
            </Provider>
          </Canvas>
          <Loader containerStyles={loaderStyle} />
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default Map
