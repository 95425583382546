export const translateUrl = (
  url,
  locale,
  translatedProjectSlug = null,
  translatedSchwerpunktSlug = null
) => {
  /**
   *  URL Struktur
   *  0 -> Empty
   *  1 -> wissenschaft, forschungsschwerpunkt, mobilitaetsschwerpunkt, zukunftsbild
   *  2 -> Slug
   *  3 -> projektziele
   *  4 -> Slug
   * */

  let urlParts = url.split("/")
  // console.log(url, translatedProjectSlug, translatedSchwerpunktSlug)
  // console.log(urlParts)
  // let removed = urlParts[1] === "en" ? urlParts.splice(1, 1) : urlParts
  if (urlParts[1] === "en") {
    urlParts.splice(1, 1)
  }
  let cat = urlParts[1]
  let catSlug = translatedSchwerpunktSlug
  let projektziele = urlParts[3]
  let projektzieleSlug = translatedProjectSlug

  function translateTerm(terms) {
    // console.log(terms)
    let urlArray = []
    if (terms.length > 0) {
      for (let term of terms) {
        let translated = term
        switch (term) {
          case "mobility-focus":
            translated = "mobilitaetsschwerpunkte"
            break
          case "mobilitaetsschwerpunkte":
            translated = "mobility-focus"
            break
          case "science":
            translated = "wissenschaft"
            break
          case "wissenschaft":
            translated = "science"
            break
          case "wissenschaftsschwerpunkt":
            translated = "science-focus"
            break
          case "science-focus":
            translated = "forschungsschwerpunkt"
            break
          case "projektziele":
            translated = "projectgoals"
            break
          case "projectgoals":
            translated = "projektziele"
            break
          case "zukunftsbild":
            translated = "vision"
            break
          case "vision":
            translated = "zukunftsbild"
            break
          default:
            translated = term
        }

        urlArray.push(translated)
      }
    }
    // console.log(urlArray)
    if (locale === "de_DE") {
      urlArray.unshift("", "en")
      // urlArray.unshift("en")
    } else {
      if (urlArray.length === 0) {
        urlArray.unshift("", "")
      } else {
        urlArray.unshift("")
      }
    }
    return urlArray.join("/")
  }

  if (!cat && !catSlug && !projektziele && !projektzieleSlug) {
    return translateTerm([])
  } else if (cat && !catSlug && !projektziele && !projektzieleSlug) {
    return translateTerm([cat])
  } else if (cat && catSlug && !projektziele && !projektzieleSlug) {
    return translateTerm([cat, catSlug])
  } else if (cat && catSlug && projektziele && !projektzieleSlug) {
    return translateTerm([cat, catSlug, projektziele])
  } else if (cat && catSlug && projektziele && projektzieleSlug) {
    return translateTerm([cat, catSlug, projektziele, projektzieleSlug])
  }
}
