import React from "react"
import * as THREE from "three"

const Light = () => {
  //Create a PointLight and turn on shadows for the light
  const light = new THREE.DirectionalLight(0xffffff, 0.75)
  // light.position.set(10, 20, 20)
  light.position.set(10, 15, -10)
  light.castShadow = true // default false
  //Set up shadow properties for the light
  light.shadow.mapSize.width = 5120 // default
  light.shadow.mapSize.height = 5120 // default
  // light.shadow.mapSize.width = 20240; // high
  // light.shadow.mapSize.height = 20240; // high
  light.shadow.camera.near = 0.01 // default
  light.shadow.camera.far = 1000 // default
  light.shadow.camera.top = -200 // default
  light.shadow.camera.right = 200 // default
  light.shadow.camera.left = -200 // default
  light.shadow.camera.bottom = 200 // default
  light.shadow.bias = -0.0002
  return <primitive object={light} />
}

const Lights = () => {
  return (
    <group name="__Lights">
      <ambientLight intensity={0.8} />
      {/* <directionalLight
        castShadow={true}
        shadowCameraActive={true}
        intensity={1}
        shadowCameraBottom={-3000}
        shadowCameraTop={3000}
        shadowCameraLeft={-3000}
        shadowCameraRight={3000}
        rotation={[1, 0.2, 0]}
        position={[-10, 10, 20]}
      /> */}
      <Light />
    </group>
  )
}

export default Lights
