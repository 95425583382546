import React from "react"

const IconChevronDown = props => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 20 20">
      <path
        d="M14.4,8.6c-0.2-0.2-0.5-0.2-0.7,0c0,0,0,0,0,0L10,12.3L6.3,8.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l4,4
	c0.2,0.2,0.5,0.2,0.7,0c0,0,0,0,0,0l4-4C14.5,9.2,14.6,8.9,14.4,8.6C14.4,8.7,14.4,8.7,14.4,8.6z"
      />
    </svg>
  )
}

export default IconChevronDown
